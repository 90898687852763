import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../state/root_reducer";

export interface OwnProps {
  url: string;
}

interface StateProps {
  isLoggedIn: boolean;
}

export type SideNavBarProps = OwnProps & StateProps;

export function SideNavBar(props: SideNavBarProps): JSX.Element {
  function render(): JSX.Element {
    const isSongsPage = props.url.includes("/songs");
    const isFreePlayPage = props.url.includes("/play");
    const isManageLikesPage = props.url.includes("/likes");
    const isPrivacyPage = props.url.includes("/privacy");
    const isTermsPage = props.url.includes("/terms");

    const selectedClass = "text-left font-bold text-gray-700";
    const nonSelectedClass = "text-left text-gray-700 cursor-pointer";

    return (
      <div
        className="invisible px-0 w-0 sm:visible sm:px-2 sm:w-32 md:w-44"
        style={{ backgroundColor: "rgba(75, 0, 75, 0.1)" }}
      >
        <nav className="flex flex-col mt-2 text-lg">
          <Link
            to="/songs"
            className={
              "pt-4 pb-4 pl-2 border-b border-gray-400 " +
              (isSongsPage ? selectedClass : nonSelectedClass)
            }
          >
            <span>Search</span>
          </Link>
          {props.isLoggedIn && (
            <Link
              to="/play"
              className={
                "pt-4 pb-4 pl-2 border-b border-gray-400 " +
                (isFreePlayPage ? selectedClass : nonSelectedClass)
              }
            >
              <span>Listen</span>
            </Link>
          )}
          {props.isLoggedIn && (
            <Link
              to="/likes"
              className={
                "pt-4 pb-4 pl-2 border-b border-gray-400 " +
                (isManageLikesPage ? selectedClass : nonSelectedClass)
              }
            >
              <span>Feedback</span>
            </Link>
          )}
        </nav>
        <div className="p-2" style={{ position: "absolute", bottom: 0 }}>
          <Link
            to="/terms"
            className={`text-sm ${
              isTermsPage ? selectedClass : nonSelectedClass
            }`}
          >
            Terms of Service
          </Link>
          <br />
          <Link
            to="/privacy"
            className={`text-sm ${
              isPrivacyPage ? selectedClass : nonSelectedClass
            }`}
          >
            Privacy Policy
          </Link>
          <div className="text-xs pt-2 mt-2 border-t border-gray-400 text-wrap sm:w-28 md:w-32">
            Music data, artist images, album covers, and song previews are
            provided by Spotify. Spotify is a trademark of Spotify AB.
          </div>
        </div>
      </div>
    );
  }

  return render();
}

function mapState(state: RootState): StateProps {
  return {
    isLoggedIn: state.user.currentUser?.id !== undefined,
  };
}

export default connect(mapState)(SideNavBar);
