import { connect, ResolveThunks } from "react-redux";
import { actions } from "../../state/slices/user";
import { RootState } from "../../state/root_reducer";
import { useNavigate } from "react-router";

interface StateProps {
  loggedInUserId: string | null;
}

interface DispatchProps {
  onArchiveUser: typeof actions.archiveUser;
}

type Props = StateProps & ResolveThunks<DispatchProps>;

const PrivacyPage = (props: Props): JSX.Element => {
  const oddRow = "p-1 text-sm";
  const evenRow = "bg-gray-300 p-1 text-sm";
  const navigate = useNavigate();
  return (
    <div
      className="p-4 font-bold text-gray-700 flex flex-col gap-y-2"
      style={{ overflowY: "scroll" }}
    >
      <span
        className={`block text-gray-700 font-bold text-base sm:text-lg font-sans p-1 border-b-2`}
      >
        Privacy Policy
      </span>
      <p className={oddRow}>Last Updated: November 20, 2024</p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        1. Collection and Use of Data
      </span>
      <p className={oddRow}>Vybe is a free tool for helping find new Music!</p>
      <p className={evenRow}>
        We use a proprietary engine to recommend songs based on feedback you
        give us about songs you like.
      </p>
      <p className={oddRow}>
        The collection and use of this data is subject to our Privacy Policy,
        described here. The only data we collect is the feedback you provide us
        about specific songs: seeds, likes, and dislikes, as well as your
        Spotify username. This information is solely used to generate music
        recommendations.
      </p>
      <p className={evenRow}>
        We use Spotify's Developer API to search for songs, display album art,
        and to play songs.
      </p>
      <p className={oddRow}>
        A Premium Spotify subscription is required to use most features.
      </p>
      <p className={evenRow}>
        We will never sell, disclose, or otherwise distribute any of your data.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        2. How We Collect, Use, and Share Information
      </span>
      <p className={evenRow}>
        If you choose to sign in to Vybe with your Spotify account we will
        collect your Spotify username. This information will be promptly deleted
        if you decide to delete your Vybe account. We do not share any collected
        information with anyone.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        3. Use of Cookies and Third-Party Cookies
      </span>
      <p className={evenRow}>
        We use a cryptographically signed cookie to store your login session
        data, and for no other purpose; disabling cookies may impact your
        ability to log in to the service.
      </p>
      <p className={oddRow}>
        We do not use any third party cookies or other tracking.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        4. Cookie Management Options
      </span>
      <p className={evenRow}>
        You have the option to manage and control cookies through your browser
        settings. You can choose to accept, reject, or delete cookies as you
        prefer. Please note that disabling certain cookies may affect the
        functionality and user experience of the website.
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        5. Contact
      </span>
      <p className={evenRow}>
        Any inquires about this Privacy Policy can be directed by email to
        rush8192 [at] gmail [dot] com
      </p>
      <span
        className={`block text-gray-700 font-bold text-base sm:text-md font-sans p-1 border-b-2`}
      >
        6. Account Deletion
      </span>
      <p className={evenRow}>
        We will disconnect your account and delete all of your data upon
        request, using the button below (log in to see the Delete Account
        button):
      </p>
      {props.loggedInUserId ? (
        <div className="flex">
          <div className="flex flex-shrink button-generic border-red cursor-pointer">
            <span
              className="text-red-800"
              onClick={() =>
                props.onArchiveUser(props.loggedInUserId!, navigate)
              }
            >
              Delete Account
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

function mapState(state: RootState): StateProps {
  return {
    loggedInUserId: state.user.currentUser?.id ?? null,
  };
}

const mapDispatch: DispatchProps = {
  onArchiveUser: actions.archiveUser,
};

export default connect(mapState, mapDispatch)(PrivacyPage);
